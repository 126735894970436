.chart-container {
    width: 100%;  /* Ensure it takes the full width */
    height: 505px; /* Set a fixed height for the chart */
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 10px;
    margin: 41px 0;
  
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  
    .chart-title {
      text-align: center;
      font-size: 1.2rem;
      color: #333;
    }
  }