.percentage-increase-cards {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;

    .card {
        width: 200px;
        padding: 20px;
        text-align: center;
        background: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .icon {
        font-size: 24px;
        color: #49c5b6;
        margin-bottom: 10px;
    }

    .percentage {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .description {
        font-size: 14px;
        color: #666;
    }
}
