.custom-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 300px !important;
    margin: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    }
  
    .card-icon {
      font-size: 48px;
      color: #49c5b6;
    }
  
    .card-title {
      margin-top: 10px;
      font-size: 18px;
      color: black;
      text-align: center;
    }
  }
  