.list-of-tools {
  padding: 20px;
  text-align: center;

  .breadcrumb {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #4a4a4a;
    font-family: 'Arial', sans-serif;
    padding: 10px 0;

    a {
      text-decoration: none;
      color: #007bff; // Primary brand color
      transition: color 0.3s ease;

      &:hover {
        color: #0056b3; // Darker shade for hover
        text-decoration: underline;
      }

      &:visited {
        color: #007bff;
      }

      &:focus {
        outline: none;
        text-decoration: underline;
      }
    }

    svg {
      margin: 0 5px; // Space between arrow and links
      font-size: 12px; // Adjust size of arrow icon
      color: #6c757d; // Arrow color
    }
  }

  .breadcrumb h4 {
    margin: 0;
    color: #333;
    font-size: 16px;
    font-weight: normal;
  }

  .tools-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Fixed grid with 4 items per row */
    gap: 20px; /* Spacing between cards */
    justify-items: center;
    margin-top: 20px;

    @media (max-width: 992px) {
      grid-template-columns: repeat(3, 1fr); /* Adjust to 3 items for smaller screens */
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr); /* Adjust to 2 items for tablets */
    }

    @media (max-width: 480px) {
      grid-template-columns: 1fr; /* Single column for mobile screens */
    }
  }

  .custom-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-decoration: none;
    background-color: #ffffff; /* White background for better contrast */
    border: 1px solid #dcdcdc; /* Lighter border for clean look */
    border-radius: 12px; /* Rounded corners for a modern appearance */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 100%;
    max-width: 250px; /* Fixed width for consistent sizing */
    height: 150px;

    &:hover {
      transform: scale(1.05); /* Slightly enlarge on hover */
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
    }

    .card-icon {
      width: 50px;
      height: 50px;
      margin-bottom: 10px;

      svg {
        width: 100%;
        height: 100%;
        color: #4caf50; /* Consistent green color */
      }
    }

    .card-title {
      font-size: 1.1rem;
      font-weight: bold;
      color: #333; /* Darker color for text */
      text-align: center;
    }
  }
}
