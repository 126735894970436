/* Side Bar Menu */
#left-sidebar {
    width: 250px; // Assuming $sidebar-width is 250px
    height: calc(100vh - 67px);
    float: left;
    position: fixed;
    left: 5px;
    background-color: #fafafa; // Assuming $grey-50 is very light grey
    overflow-y: scroll;
	margin-top: 75px;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &.collapsed {
        width: 65px;

        .collapse-btn {
            left: 40px;
        }

        .user-account,
        .sidebar-nav span {
            display: none;
        }
    }

    .collapse-btn {
        position: absolute;
        top: 0px;
        left: 220px;
        background: none;
        border: none;
        font-size: 18px;
        cursor: pointer;
        z-index: 1000;

        &:focus {
            outline: none;
        }
    }

    .sidebar-nav {
        margin-top: 21px;
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
        }

        .metismenu {
            display: flex;
            flex-direction: column;

            > li {
                padding-bottom: 5px;
                flex: 1 1 0%;
                display: flex;
                flex-direction: column;
                position: relative;

                a {
                    border-left: 5px solid transparent;
                    display: flex;
                    align-items: center;
                    padding: 10px 15px;
                    font-size: 28px;
                    text-decoration: none;
                    transition: all 0.3s ease;

                    i {
                        color: #49c5b6;
                        font-size: 30px;
                    }

                    span {
                        font-size: 18px;
                        color: #17191c;
                        margin-left: 10px;
                    }
                }

                &.active > a {
                    background-color: #f1f1f1;
                }

                .active a {
                    font-weight: bold;
                }
            }

            ul a {
                padding: 10px 15px 10px 57px;
                position: relative;
                color: #757575; // Assuming $grey-600 is grey
                font-size: 16px; // Assuming $font-size is 16px

                &::before {
                    content: '--';
                    position: absolute;
                    left: 19px;
                }
            }

            ul ul a {
                padding: 10px 15px 10px 70px;
            }

            ul.collapse a {
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }

            a {
                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                    background: #f1f1f1;
                }
            }

            .has-arrow::after {
                color: #b5b5b5;
            }
        }
    }
}
