.table-component {
  padding: 20px;
}

table {
  width: 80%; /* Keeps the table width visible */
  border-collapse: separate; /* Adds spacing for a 3D effect */
  border-spacing: 0; /* Remove default spacing */
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for 3D effect */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Ensure corners apply to children */
}

th,
td {
  padding: 15px; /* Increase padding for a cleaner look */
  text-align: center;
  border: none; /* Remove borders for a smoother design */
}

th {
  background-color: #49c5b6; /* Header background color */
  color: white; /* Text color */
  font-weight: bold; /* Bold text for headers */
  text-transform: uppercase; /* Make header text uppercase */
  box-shadow: inset 0 -2px 4px rgba(0, 0, 0, 0.1); /* Subtle inner shadow for depth */
}

td {
  background-color: #ffffff; /* Base cell background */
  color: #333; /* Base cell text color */
  font-size: 14px; /* Adjust font size for readability */
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.05); /* Subtle inner shadow */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth hover transition */
}

/* Alternating row background for better readability */
tr:nth-child(even) td {
  background-color: #f9f9f9; /* Light gray for alternate rows */
}

/* Hover effect for rows */
tr:hover td {
  background-color: #eaf7f7; /* Slightly tinted background on hover */
  transform: translateY(-2px); /* Lift rows slightly for a 3D effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow for the hover effect */
}

/* Table borders for cleaner separation */
th:first-child,
td:first-child {
  border-left: none; /* Remove left border for the first column */
}

th:last-child,
td:last-child {
  border-right: none; /* Remove right border for the last column */
}
