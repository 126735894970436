.page-loader-wrapper {
    z-index: 99999999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @extend .align-center;

    p {
        @extend .m-t-10;
        color: $col-white;
    }

    .loader {
        position: relative;
        top: calc(40% - 30px);
    }
}



