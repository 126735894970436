/* Navigation */

/* Top Nav Bar */
.navbar-fixed-top {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    background: #fff; // Assuming $col-white is white
    z-index: 99;
    padding: 0;
    border-bottom: 1px solid #e0e0e0; // Assuming $grey-300 is light grey

    .navbar-btn {
        display: none;
        float: left;
        padding: 0;

        button {
            font-size: 20px;
            padding-left: 0;
            line-height: .7;
            border: none;
            background: none;
            outline: none;
        }
    }

    .navbar-brand {
        padding: 15px 0;
        position: relative;

        img {
            width: 98px;
            vertical-align: top;
            margin-top: -11px;
            margin-left: 53px;
        }
    }

    .navbar-right {
        width: calc(100% - 250px);

        #navbar-search {
            float: left;
            margin-right: 20px;
            margin-top: 6px;

            .form-control {
                padding-left: 20px;
                height: 40px;
            }

            i {
                font-size: 12px;
            }
        }

        .navbar-nav {
            display: inline-block;
            > li {
                display: inline-block;
            }
        }
    }
}

.navbar-nav {
    margin: 0;
    margin-right: 15px;

    .icon-menu {
        display: inline-block;
        padding: 15px;
        position: relative;

        i {
            font-size: 18px;
            color: #616161; // Assuming $grey-700 is dark grey
        }

        .notification-dot {
            border-radius: 50%;
            width: 8px;
            height: 8px;
            position: absolute;
            top: 12px;
            right: 7px;
            background-color: #000; // Assuming $col-dark is black
        }
    }
}

#navbar-menu {
    float: right;
}
