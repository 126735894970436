.lab-card {
  border: 1px solid #ddd;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s, box-shadow 0.3s;
  background: #ffffff; /* White background */

  &:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  &__map img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid #eee;
  }

  &__details {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    h3 {
      margin: 0;
      font-size: 1.3rem;
      color: #333;
      font-weight: bold;
    }

    p {
      margin: 0;
      font-size: 1rem;
      color: #555;
      line-height: 1.5;
    }
  }
}
