/* Layout */

#wrapper {
    &:before,
    &:after {
        height: 100vh;
        width: 5px;
        position: fixed;
        content: '';
        z-index: 999;
        top: 0;
    }
    &:before {
        left: 0;
    }
    &:after {
        right: 0;
    }
}

#main-content {
    width: calc(100% - 250px); // Assuming $sidebar-width is 250px
    float: right;
    position: relative;
    margin-top: 65px;
    padding: 0 10px;
}

html.fullscreen-bg,
html.fullscreen-bg body,
html.fullscreen-bg #wrapper {
    height: 100%;
}

.vertical-align-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    display: table;
}

.vertical-align-middle {
    display: table-cell;
    vertical-align: middle;
}
