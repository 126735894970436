.data-visualizations {
  margin: 20px 40px;

  .breadcrumb {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #4a4a4a;
    font-family: 'Arial', sans-serif;
    padding: 10px 0;

    a {
      text-decoration: none;
      color: #007bff; // Set the link color to the primary brand color
      transition: color 0.3s ease;

      &:hover {
        color: #0056b3; // A darker shade of the primary color for hover
        text-decoration: underline; // Underline when hovered
      }

      &:visited {
        color: #007bff;
      }

      &:focus {
        outline: none;
        text-decoration: underline;
      }
    }

    svg {
      margin: 0 5px; // Space between arrow and links
      font-size: 12px; // Adjust the size of the arrow icon
      color: #6c757d; // Set the color for the arrow
    }
  }

  .selectors-container {
    min-height: 169px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    .selectors {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;

      .select-box {
        flex: 1 1 auto;
        min-width: 200px;

        &:last-child {
          margin-right: 0;
        }

        label {
          display: block;
          margin-bottom: 8px;
          font-weight: bold;
          color: #333;
          font-size: 14px;
        }

        .custom-multiselect .p-multiselect {
          background-color: #ffffff;
          border: 1px solid #ced4da;
          border-radius: 5px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          transition: border-color 0.3s ease;
          padding: 8px;
        }

        .custom-multiselect .p-multiselect:hover {
          border-color: #007bff;
        }

        .custom-datetime-picker .react-datetime-picker__wrapper {
          position: relative;
          height: auto;
          display: flex;
          align-items: center;
          border: 1px solid #45e5c3;
          border-radius: 5px;
          padding: 8px;
          background-color: #45e5c3;
          z-index: 10;
        }
      }
    }

    .confirm-button {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      font-weight: bold;
      transition: background-color 0.3s ease, transform 0.2s ease;
      animation: bounce 2s infinite;
    }

    .confirm-button:hover {
      background-color: #0056b3;
      transform: translateY(-2px);
    }
  }

  .visualization-container {
    display: flex;
    flex-direction: column; /* Stack table, graph, and paginator vertically */
    align-items: center; /* Center content horizontally */
    width: 100%;

    .table-graph-container {
      display: flex;
      justify-content: space-between; /* Space between table and graph */
      width: 100%;
      margin-bottom: 20px; /* Add spacing below table and graph */
    }

    .paginator-container {
      display: flex;
      justify-content: center; /* Center paginator horizontally */
      width: 100%; /* Full width to align with container */
      margin-top: 20px; /* Add spacing above paginator */
    }
  }

  .image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .facility-image {
      max-width: 80%;
      height: auto;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      transition: transform 0.3s ease-in-out;
    }

    .facility-image:hover {
      transform: scale(1.05);
    }
  }

  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }

  @keyframes slideUp {
    from {
      transform: translateY(100px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .animate-fade-in {
    animation: fadeIn 1s ease-in-out;
  }

  .animate-bounce {
    animation: bounce 2s infinite;
  }

  .animate-slide-up {
    animation: slideUp 0.5s ease-in-out;
  }
}
