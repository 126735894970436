.home-container {
  margin-top: -82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  
    h1 {
      margin-bottom: 40px;
      margin-right: 1204px;
      margin-top: -80px;
  
      @media (max-width: 1200px) {
        margin-right: 0;
      }
  
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  
    .cards-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 170px;

  
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }